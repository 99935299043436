import { template as template_811ef2ab00e74b92b40aca1297568dd9 } from "@ember/template-compiler";
const FKText = template_811ef2ab00e74b92b40aca1297568dd9(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
