import { template as template_586833e1cff8428b985deaa122c6fc88 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_586833e1cff8428b985deaa122c6fc88(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
