import { template as template_7b454405db1048e5925dc519d3bae321 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7b454405db1048e5925dc519d3bae321(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
