import { template as template_31c4ac5e177a4ffba3c86e71b72b6be5 } from "@ember/template-compiler";
const FKLabel = template_31c4ac5e177a4ffba3c86e71b72b6be5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
